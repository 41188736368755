<template>
  <footer class="clearfix">
    <a class="footer-btn" :href="onLine" target="_blank">
      <img src="../assets/header/consult.png"/>
      在线咨询
    </a>
    <a class="footer-btn" href="tel:028-6787-9897‬">
      <img src="../assets/header/tel.png"/>
      电话咨询
    </a>
  </footer>
</template>
<script>
import { onLine } from "@/data.ts";
export default {
  data(){
    return {
      onLine
    }
  }
}
</script>
<style lang="less" scoped>
footer{
  height: 55px;
  box-sizing: border-box;
  border: 2px solid #E80B10;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 2;
  .footer-btn{
    float: left;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    &:first-child{
      color: #E80B10;
      background-color: #fff;
    }
    &:last-child{
      background-color: #E80B10;
      color: #fff;
    }
    img{
      width: 23px;
      height: 23px;
      display: block;
      margin-right: 10px;
    }
  }
}
</style>
