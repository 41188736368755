<template>
  <div class="project-box">
    <div class="project-title">
      <h3>{{item.name}}</h3>
      <a :href="onLine" target="_blank">获取折扣</a>
    </div>
    <section v-for="(obj,key) in item.projects" :key="key">
      <h4>{{obj.name}}</h4>
      <p>{{obj.instructions}}</p>
    </section>
  </div>
</template>
<script lang="ts">
import { onLine } from "@/data";
export default {
  props:{
    item:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      onLine
    }
  }
}
</script>
<style lang="less" scoped>
.project-box{
  border-top: 6px solid #F4F4F4;
  padding: 0 20px;
  .project-title{
    font-size: 17px;
    color: #383838;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 54px;
    text-transform: uppercase;
    >h3{
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    >a{
      margin-left: 20px;
      text-align: center;
      display: inline-block;
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      background: #E80B10;
      border: 0 none;
      border-radius: 2px;
      font-size: 14px;
      color: #fff;
      font-weight: 500;
    }
  }
  >section{
    border-top: 1px solid #EAEAEA;
    padding: 20px 0;
    color: #383838;
    text-transform: uppercase;
    >h4{
      font-size: 15px;
      font-weight: 500;
    }
    >p{
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      margin-top: 10px;
      text-align: justify;
    }
  }
}
</style>